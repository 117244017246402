import ExtensionButton from "./ExtensionButton.component"

const NoSession = () => {
  return (
    <div className="flex w-full h-full items-center justify-center">
        <div className="flex flex-col items-center space-y-4">
            <div className="w-16 h-16 bg-primary-darker bg-opacity-3"/>
            <p className="text-sm font-medium text-darker text-opacity-75">Start your first interaction anywhere</p>
            <div className="mt-5" data-tour='home'>
                <ExtensionButton />
            </div>
        </div>
    </div>
  )
}

export default NoSession