import { PaymentMethod } from '@stripe/stripe-js';
import Cookies from 'js-cookie';
import { makeAutoObservable, toJS } from 'mobx';

import { getDocumentUrl } from '../utils/getDocumentUrl';

export type UserPermissions = {
    id: number;
    spaceId: string;
    associatedMemberId: number;
    role: string;
};

export type UserEmail = {
    id?: string;
    email: string;
    verified?: boolean;
};

export type OnboaringTour = {
    showWelcome: boolean;
    startTour: boolean;
    extension: boolean;
    navbarMenu: boolean;
    eva: boolean;
    evaTemplate: boolean;
    evaMetamarker: boolean;
    evaTestMode: boolean;
};

export type UserData = {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    profilePictureUrl?: string;
    profilePictureId?: number;
    createdAt?: Date;
    organisationSpaceId?: string;
    permissions: UserPermissions[];
    userType: 'BLUEBERRY' | 'ENTERPRISE' | 'BLUEBERRY_PRO';
    userEmail: UserEmail[];
    onboardingTour: OnboaringTour;
};

export const countryList = ['United-Kingdom' /* 'France', 'Germany',  'Italy', 'Spain', 'Japan' */];
export type CardResponse = {
    card: PaymentMethod;
    isDefault: boolean;
    isExpired: boolean;
};
export class UserStore {
    public token: string | null;
    userData: UserData | null = null;
    paymentMethods: CardResponse[] | null = null;
    userPermission = true;

    constructor(token: string | null) {
        if (token) {
            this.token = token;
        } else {
            this.token = localStorage.getItem('jwt');
        }
        makeAutoObservable(this);
    }

    get getUserData() {
        return this.userData;
    }

    updateUserData = (newData: UserData): void => {
        this.userData = newData;
        if (newData?.profilePictureId) this.setProfilePictureUrl(newData?.profilePictureId);
    };

    updateUserEmail = (emails: string[]): void => {
        if (!this.userData) return;
        const newUserEmails: UserEmail[] = emails.map((email) => {
            const existingEmail =
                this.userData?.userEmail && this.userData.userEmail?.find((userEmail) => userEmail.email === email);
            return {
                email,
                verified: existingEmail?.verified ?? false,
            };
        });
        this.userData.userEmail = newUserEmails;
    };

    deleteUserEmail = (email: string): void => {
        if (this.userData?.userEmail) {
            const filtered = this.userData.userEmail.filter((userEmail) => {
                if (userEmail) return userEmail.email !== email;
                return true;
            });
            this.userData.userEmail = filtered;
        }
    };

    getPaymentMethods = () => {
        return this.paymentMethods;
    };
    setPaymentMethods = (paymentMethods: CardResponse[]) => {
        this.paymentMethods = paymentMethods;
    };

    addPaymentMethod = (method: CardResponse) => {
        if (this.paymentMethods) {
            const tmp = [...this.paymentMethods];
            tmp.push(method);
            this.setPaymentMethods(tmp);
        }
    };
    deletePaymentMethod = (cardId: string) => {
        if (this.paymentMethods) {
            this.setPaymentMethods(this.paymentMethods?.filter((method) => method.card.id !== cardId));
        }
    };

    setProfilePictureUrl = (documentId: number) => {
        if (this.userData) this.userData.profilePictureUrl = getDocumentUrl(documentId);
    };

    async setToken(token: string | null) {
        this.token = token;
        if (token) {
            localStorage.setItem('jwt', token);
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
            // Check if the JWT is expired
            const expirationTimeInSeconds = decodedToken.exp;
            const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
            if (expirationTimeInSeconds < currentTimestampInSeconds) {
                console.log('JWT has expired.');
            } else {
                // Calculate the remaining time until expiration
                const timeToExpirationInSeconds = expirationTimeInSeconds - currentTimestampInSeconds;
                // Store the JWT in a cookie with the same expiration time as the JWT
                Cookies.set('bberry-token', token, { expires: timeToExpirationInSeconds / (60 * 60 * 24) }); // Convert seconds to days
            }
        } else {
            console.log('Removing the token');
            localStorage.removeItem('jwt');
            Cookies.remove('bberry-token');
            Cookies.remove('bberry-user');
        }
    }

    async setBberryUserCookie(userId: number, userType: string) {
        const user = {
            userId: userId,
            userType: userType,
        };
        Cookies.set('bberry-user', JSON.stringify(user), { expires: 365 });
    }

    async updateBberryUserCookie(userType: string) {
        const user = {
            userId: this.userData?.id,
            userType: userType,
        };
        Cookies.set('bberry-user', JSON.stringify(user), { expires: 365 });
    }

    updatePaymentMethodStatus = (ind: number | undefined) => {
        if (!ind) return;
        const tmpMethods = toJS(this.paymentMethods);
        if (tmpMethods && tmpMethods[ind]) {
            const tmp = [...tmpMethods];
            tmp[ind].isDefault = false;
            this.setPaymentMethods(tmp);
        }
    };

    updateUserType = (userType: 'BLUEBERRY' | 'ENTERPRISE' | 'BLUEBERRY_PRO') => {
        if (this.userData) this.userData.userType = userType;
    };

    updateOnboardingTour = (status: boolean, name: keyof OnboaringTour) => {
        if (this.userData) this.userData.onboardingTour[name] = status;
    };

    skipOnboardingTour = () => {
        if (this.userData) {
            this.userData.onboardingTour.showWelcome = false;
            this.userData.onboardingTour.startTour = false;
        }
    };
}

const defaultString = 'DEFAULT';

export const defaultUser = {
    firstName: defaultString,
    id: 0,
    lastName: defaultString,
    type: 'BUSINESS' as const,
    email: defaultString,
};

export type UpdateUserDto = {
    firstName?: string;
    lastName?: string;
    email?: string;
};
