import React from 'react';

import { TourProvider, useTour, Position } from '@reactour/tour';
import '@reactour/popover/dist/index.css';
import { observer } from 'mobx-react-lite';

import fillMetamarkerIcon from '../../assets/icons/icon-fill-metamarker.svg';
import planetIcon from '../../assets/icons/icon-planet.svg';
import blankAIIcon from '../../assets/images/blankAI.svg';
import { useSettings } from '../../hooks/useSettings';
import { Button } from '../basic/Button.component';

type OppositeSideType = {
    top: string;
    bottom: string;
    right: string;
    left: string;
};

const opositeSide: OppositeSideType = {
    top: 'bottom',
    bottom: 'top',
    right: 'left',
    left: 'right',
};

const TourComponent = ({
    children,
    currentStep,
    handleCurrentStep,
}: {
    children: React.ReactNode;
    currentStep: number;
    handleCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}) => {
    const { setIsOpen } = useTour();
    const { skipOnboardingTour } = useSettings();
    const steps = [
        {
            selector: "[data-tour='home']",
            content: (
                <div className='flex flex-col'>
                    <div className='flex items-center'>
                        <img src={blankAIIcon} alt='blank_ai' />
                        <p className='font-semibold'>Get blank_ai</p>
                    </div>
                    <p className='text-white text-opacity-75 px-4'>
                        Stay aligned in daily communications with your AI companion. Blank_ai enhances understanding,
                        reduces confusion, and drives progress - helping you achieve objectives faster.
                    </p>
                </div>
            ),
            position: 'left' as Position,
        },
        {
            selector: "[data-tour='menu']",
            content: (
                <div className='flex flex-col'>
                    <div className='flex items-center p-4'>
                        <p className='font-semibold'>Let Get Started</p>
                    </div>
                    <div className='space-y-2 px-4 text-white text-opacity-75'>
                        <p>Select an Option</p>
                        <div className='flex space-x-2 bg-primary-tour p-4 items-center'>
                            <img src={planetIcon} alt='planet-icon' />
                            <p className='whitespace-nowrap'>Create an Organization</p>
                        </div>
                        <p>
                            By setting up your Organization, you can invite colleagues, control internal policies and
                            collaborate in the most efficient way.
                        </p>
                        <div className='flex space-x-2 bg-primary-tour p-4 items-center text-xs'>
                            <div
                                className='flex rounded-md w-5 h-5 items-center p-1 justify-center'
                                style={{
                                    border: '1.25px solid rgba(255, 255, 255, 0.25)',
                                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                }}
                            >
                                <p className='text-white font-semibold'>S</p>
                            </div>
                            <p className='whitespace-nowrap'> Create a New Space</p>
                        </div>
                        <p>
                            Spaces are used to group interaction, helping each other going towards the same goals faster
                            whilst keeping interactions private.
                        </p>
                    </div>
                </div>
            ),
            position: 'left' as Position,
        },
        {
            selector: "[data-tour='eva']",
            content: (
                <div className='flex flex-col px-4 mt-4'>
                    <div className='flex items-center'>
                        <p className='font-semibold'>Build your protocol</p>
                    </div>
                    <p className='text-white text-opacity-75 mt-2'>
                        Create custom guidelines reflecting your company values, code of conduct, and diversity, equity
                        and inclusion priorities. Establish guardrails to encourage employees to act in alignment with
                        company policies
                    </p>
                </div>
            ),
            position: 'right' as Position,
        },
        {
            selector: "[data-tour='template-menu']",
            content: (
                <div className='flex flex-col px-4 mt-4'>
                    <div className='flex items-center'>
                        <p className='font-semibold'>How do you want to start?</p>
                    </div>
                    <div className='space-y-2 text-white text-opacity-75 mt-2'>
                        <div className='flex space-x-2 bg-primary-tour p-4 items-center'>
                            <p className='whitespace-nowrap'>Start Blank</p>
                        </div>
                        <p>Starting from scratch is always a rewarding challenge!</p>
                        <div className='flex space-x-2 bg-primary-tour p-4 items-center text-xs'>
                            <p className='whitespace-nowrap'> Generate from Title & Description </p>
                        </div>
                        <p>
                            Utilise EVA’s AI’s capabilities by simply giving it a title and a short description of what
                            you would like to oversee.
                        </p>
                        <div className='flex space-x-2 bg-primary-tour p-4 items-center'>
                            <p className='whitespace-nowrap'>Select a premade template</p>
                        </div>
                        <p>
                            Dive into our constantly updated library of blueprints and pick what seems the best to
                            kick-off.
                        </p>
                    </div>
                </div>
            ),
            position: 'top' as Position,
        },
        {
            selector: "[data-tour='eva-metamarker']",
            content: (
                <div className='flex flex-col px-4 mt-4'>
                    <div className='flex items-center'>
                        <p className='font-semibold'>Create your first metamarker</p>
                    </div>
                    <div className='space-y-2 text-white text-opacity-75 mt-4'>
                        <div className='flex space-x-2 bg-primary-tour p-4 items-center'>
                            <p className='whitespace-nowrap'>Trigger</p>
                        </div>
                        <div className='flex space-x-2 bg-primary-tour p-4 items-center text-xs'>
                            <p className='whitespace-nowrap'> Metamarker </p>
                        </div>
                        <div className='flex space-x-2 bg-primary-tour p-4 items-center'>
                            <p className='whitespace-nowrap'>Weight</p>
                        </div>
                        <div className='flex space-x-2 bg-primary-tour p-4 items-center'>
                            <p className='whitespace-nowrap'>Nuances</p>
                        </div>
                    </div>
                    <Button className='flex space-x-2 mt-4 w-1/2' size='oval' variant='fillMetamarker'>
                        <img src={fillMetamarkerIcon} alt='fill_metamarker' />
                        <p>Fill Metamarker</p>
                    </Button>
                </div>
            ),
            position: 'left' as Position,
        },
    ];

    const handleClose = () => {
        setIsOpen(false);
        handleCurrentStep(-1);
    };

    function isOppositeSideKey(key: string): key is keyof OppositeSideType {
        return key === 'top' || key === 'bottom' || key === 'right' || key === 'left';
    }

    function doArrow(position: string, verticalAlign: string, horizontalAlign: string) {
        if (!position || position === 'custom') {
            return {};
        }

        const width = 16;
        const height = 12;
        const color = 'rgba(33, 33, 33, 1)';
        const isVertical = position === 'top' || position === 'bottom';
        const spaceFromSide = 20;

        const obj = {
            [`--rtp-arrow-${
                isVertical ? (isOppositeSideKey(horizontalAlign) ? opositeSide[horizontalAlign] : '') : verticalAlign
            }`]: height + spaceFromSide + 'px',
            [`--rtp-arrow-${opositeSide[position as keyof OppositeSideType]}`]: -height + 2 + 'px',
            [`--rtp-arrow-border-${isVertical ? 'left' : 'top'}`]: `${width / 2}px solid transparent`,
            [`--rtp-arrow-border-${isVertical ? 'right' : 'bottom'}`]: `${width / 2}px solid transparent`,
            [`--rtp-arrow-border-${position}`]: `${height}px solid ${color}`,
        };
        return obj;
    }
    const popoverPadding = 10;
    const styles = {
        close: (base: React.CSSProperties) => ({
            ...base,
            left: 'auto',
            right: 15,
            top: 20,
            color: 'rgba(255, 255, 255, 0.35)',
            width: '10px',
            height: '10px',
        }),
        //@ts-ignore
        popover: (base: React.CSSProperties, state) => {
            return {
                ...base,
                width: '397px',
                height: 'auto',
                borderRadius: '7px',
                border: '1px solid rgba(0, 0, 0, 0.5)',
                backgroundColor: '#212121',
                fontFamily: 'Poppins',
                zIndex: '99999999999',
                color: 'rgba(255, 255, 255, 1)',
                padding: '0',
                top: '15px',
                // marginTop: '150px',
                // marginLeft: 100,
                borderInlineStart: '4px solid rgba(90, 68, 255, 1)',
                fontSize: '12px',
                ...doArrow(state.position, state.verticalAlign, state.horizontalAlign),
            };
        },
        maskArea: (base: React.CSSProperties) => ({
            ...base,
            rx: 0,
        }),
        maskWrapper: (base: React.CSSProperties) => ({
            ...base,
            color: 'rgba(0, 0, 0, 0.5)',
        }),
    };

    return (
        <div className='w-full h-full'>
            <TourProvider
                disableFocusLock
                showBadge={false}
                showDots={false}
                steps={steps}
                styles={styles}
                currentStep={currentStep}
                setCurrentStep={handleCurrentStep}
                padding={{ popover: popoverPadding + 5 }}
                beforeClose={() => handleClose()}
                prevButton={() => {
                    return (
                        <div className='flex space-x-2 px-4 text-white text-opacity-75 pb-4'>
                            <p>Done?</p>
                            <button
                                style={{
                                    textDecoration: 'underline',
                                }}
                                onClick={() => {
                                    skipOnboardingTour();
                                    handleClose();
                                }}
                            >
                                Click here to skip
                            </button>
                        </div>
                    );
                }}
                nextButton={() => {
                    return <></>;
                }}
            >
                {children}
            </TourProvider>
        </div>
    );
};

export default observer(TourComponent);
