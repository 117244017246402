import { useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';

import useMediaQuery from '../../hooks/useMediaQuery';
import { useSession } from '../../hooks/useSession';
import { store } from '../../store';
import { SessionByDomain } from '../../store/SessionStore';
import { SIZES } from '../../utils/constants';
import ExtensionButton from './ExtensionButton.component';
import ExtensionModel from './ExtensionModel.component';
import NoSession from './NoSession.component';
import SessionDomain from './SessionDomain.component';
import SessionSkeleton from './SessionSkeleton.component';
import Welcome from './Welcome.component';

const SessionHomePage = () => {
    const bberryExtension = JSON.parse(localStorage.getItem('bberry-extension') || 'false');
    const isMobile = useMediaQuery(SIZES.MOBILE_WIDTH);
    const [sessions, setSession] = useState<SessionByDomain[]>([]);

    const { groupSessionsByDomain, loading } = useSession();
    const showWelcome = store.user.userData?.onboardingTour?.showWelcome || false;

    useEffect(() => {
        if (store.extensionStatus || bberryExtension) {
            store.setExtensionStatus(true);
        }
        groupSessionsByDomain().then((res) => setSession(res));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDeleteSessionsByDomain = (domain: string) => {
        const newSessions = sessions.filter((session) => session.domain !== domain);
        setSession(newSessions);
    };

    const handleDeleteSession = (domain: string, id: number) => {
        const newSessions = sessions.map((session) => {
            if (session.domain === domain) {
                const newData = session.data.filter((data) => data.id !== id);

                // Check if newData is empty, and delete the session if true
                if (newData.length === 0) {
                    return null; // Returning null will filter out this session
                }

                return {
                    domain: session.domain,
                    data: newData,
                };
            }
            return session;
        });

        // Filter out sessions that were marked for deletion (null)
        const filteredSessions = newSessions.filter((session) => session !== null) as SessionByDomain[];

        setSession(filteredSessions);
    };

    return (
        <div className='relative'>
            {!bberryExtension && (
                <div className='absolute right-0' data-tour='home'>
                    <ExtensionButton />
                </div>
            )}

            {!isMobile && <ExtensionModel />}
            {showWelcome ? (
                <div className='mt-10'>
                    <Welcome />
                </div>
            ) : (
                <div className='w-full h-full flex items-center mt-10 flex-col'>
                    {/* {!isMobile && (
                        <div>
                            {!store.extensionStatus ? (
                                <div className='absolute right-5'>
                                    <ExtensionButton />
                                </div>
                            ) : (
                                sessions.length === 0 && (
                                    <div className='flex space-x-2 items-center'>
                                        <div
                                            className='flex items-center justify-center w-8 h-8 
                                                bg-primary-darker bg-opacity-3 rounded-full border
                                                border-blue-dark border-opacity-4'
                                            style={{
                                                boxShadow: '0px 2px 4px 0px rgba(90, 68, 255, 0.40)',
                                            }}
                                        >
                                            <BlueberryLogo className='w-6 h-6 ' />
                                        </div>
                                        <div className='text-darker text-opacity-75 font-semibold text-center text-base'>
                                            <p>Start your first interaction anywhere</p>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    )} */}
                    {loading ? (
                        <SessionSkeleton number={2} className='w-full max-w-5xl mx-auto mt-10' />
                    ) : !sessions || sessions.length === 0 ? (
                        <NoSession />
                    ) : (
                        sessions.map((session, index) => (
                            <div className='w-full max-w-5xl mt-10 px-4' key={index}>
                                <SessionDomain
                                    domain={session.domain}
                                    data={session.data}
                                    handleDeleteSessionsByDomain={handleDeleteSessionsByDomain}
                                    handleDeleteSession={handleDeleteSession}
                                />
                            </div>
                        ))
                    )}
                </div>
            )}
        </div>
    );
};

export default observer(SessionHomePage);
